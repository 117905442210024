import { CardMedia, Dialog, Grid, Typography } from "@mui/material";


// Berhubung tak ada mock up untuk halaman detail, maka halaman detail saya buat sebagaimana saya rasa cukup.











export default function DetailDokter(props) {
  var parse = require("html-react-parser");
  return (
    <div>
      <Dialog
        fullScreen
        style={{ padding: "5%" }}
        open={props.openDetailDokter}
        onClose={props.onClose}
      >
        <div style={{ padding: "3% " }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <CardMedia
                className="doctor-thumbnail"
                component="img"
                image={props.dataDetailDokter.photo.formats.medium}
                alt={props.dataDetailDokter.id}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" gutterBottom component="div">
                {props.dataDetailDokter.name}
              </Typography>
              <Typography variant="h6" gutterBottom component="div">
                {props.dataDetailDokter.specialization.name} |{" "}
                {props.dataDetailDokter.hospital[0].name}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                SIP : {props.dataDetailDokter.sip}
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="div">
                Experience : {props.dataDetailDokter.experience}
              </Typography>
              <Typography variant="button" display="block" gutterBottom>
                Price : {props.dataDetailDokter.price.formatted}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {parse(props.dataDetailDokter.about)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <CardMedia
                className="doctor-thumbnail"
                component="img"
                image={props.dataDetailDokter.hospital[0].image.formats.medium}
                alt={props.dataDetailDokter.id}
              />
              <Typography variant="caption" gutterBottom>
                {parse(props.dataDetailDokter.hospital[0].name)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "./App.css";
import { useEffect, useState } from "react";
import "./App.css";
import { APIRequest } from "./utils/PanggilanAPI";
import DetailDokter from "./components/DetailDokter";

export default function App() {
  
  // Inisiasi html parser untuk nanti menampilkan data 'about' dari tiap Dokter.
  var parse = require("html-react-parser");

  // State untuk memonitor penerapan filter dan search.
  const [filterKeywords, setFilterKeywords] = useState(""),
        [filterHospital, setFilterHospital] = useState("inactive"),
        [filterSpecialization, setFilterSpecialization] = useState("inactive"),

  // State untuk mengirimkan data ke widget/dialog data detail Dokter.
        [dataDetailDokter, setDataDetailDokter] = useState(),
        [openDetailDokter, setOpenDetailDokter] = useState(false),

  // State untuk menampung hasil panggilan API.
        [daftarDokter, setDaftarDokter] = useState([]);

  // Panggilan api ke https://run.mocky.io/v3/c9a2b598-9c93-4999-bd04-0194839ef2dc. Apabila berhasil, masukkan data ke state 'daftarDokter'.
  const getDaftarDokter = async () => {
    await APIRequest({
    method: "GET",url: "c9a2b598-9c93-4999-bd04-0194839ef2dc",})
    .then((res) => { if (res.status === 200) { setDaftarDokter(res.data.data);}}).catch((err) => {});};

  // Lakukan panggilan API ketika halaman dimuat.
  useEffect(() => {
    getDaftarDokter();
  }, []);

  // Setiap kali nilai state filterHospital berubah, lakukan penyortiran berdasarkan huruf-huruf awal data rumah sakit.
  useEffect(() => {
    const newList = [...daftarDokter];
    filterHospital === "desc"
      ? newList.sort((a, b) =>
          a.hospital[0].name
            .toLowerCase()
            .localeCompare(b.hospital[0].name.toLowerCase())
        )
      : filterHospital === "asc"
      ? newList.sort((b, a) =>
          a.hospital[0].name
            .toLowerCase()
            .localeCompare(b.hospital[0].name.toLowerCase())
        )
      : getDaftarDokter();
    setDaftarDokter(newList);
  }, [filterHospital]);

  // Setiap kali nilai state filterSpecialization berubah, lakukan penyortiran berdasarkan huruf-huruf awal data spelialisasi.
  useEffect(() => {
    const newList = [...daftarDokter];
    filterSpecialization === "desc"
      ? newList.sort((a, b) =>
          a.specialization.name
            .toLowerCase()
            .localeCompare(b.specialization.name.toLowerCase())
        )
      : filterSpecialization === "asc"
      ? newList.sort((b, a) =>
          a.specialization.name
            .toLowerCase()
            .localeCompare(b.specialization.name.toLowerCase())
        )
      : getDaftarDokter();
    setDaftarDokter(newList);
  }, [filterSpecialization]);

  return (
    <div className='website-base-wrapper'>

      {/* Ini merupakan kode untuk menampilkan dialog detail data Dokter. Ketika halaman baru dimuat, nilai state dataDetailDokter akan null, sehingga dialognya tak perlu dirender.   */}
      {/* Dialog hanya dirender ketika user klik tombol Detail.   */}
      {dataDetailDokter != null ? ( <DetailDokter dataDetailDokter={dataDetailDokter} openDetailDokter={openDetailDokter} onClose={() => { setOpenDetailDokter(false);}} />
      ) : (  <></> )}

      {/* Bagian Header dan filter mulai   */}
      <Paper className='website-toolbar' elevation={3}>
        <Typography variant='h6' gutterBottom component='div'>
          Doctor Finder
        </Typography>

        <TextField
          value={filterKeywords}
          onChange={(e) => setFilterKeywords(e.target.value)}
          label='Keyword ( berdasarkan nama Dokter )'
          variant='outlined'
        />

        <FormControl style={{ marginLeft: "2%", width: "20%" }}>
          <InputLabel id='demo-simple-select-label'>Hospital</InputLabel>
          <Select
            value={filterHospital}
            onChange={(e) => setFilterHospital(e.target.value)}
            label='Hospital'
            labelId='hospital-label'>
            <MenuItem value='desc'>A - Z</MenuItem>
            <MenuItem value='asc'>Z - A</MenuItem>
            <MenuItem value=''>Nonaktifkan</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginLeft: "2%", width: "20%" }}>
          <InputLabel id='demo-simple-select-label'>Specialization</InputLabel>
          <Select
            value={filterSpecialization}
            onChange={(e) => setFilterSpecialization(e.target.value)}
            label='Specialization'
            labelId='specialization-label'>
            <MenuItem value='desc'>A - Z</MenuItem>
            <MenuItem value='asc'>Z - A</MenuItem>
            <MenuItem value=''>Nonaktifkan</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      {/* Bagian Header dan filter selesai   */}

      {/* Bagian daftar dokter mulai   */}
      <Grid className='doctor-list-wrapper' container spacing={2}>
        {daftarDokter

          // Kode ini digunakan untuk filter daftar dokter berdasarkan nama dokter. 
          .filter((dokter) => {
            if (filterKeywords === "") {
              return dokter;
            } else if (
              dokter.name.toLowerCase().includes(filterKeywords.toLowerCase())
            ) {
              return dokter;
            }
          })

          // Setelah difilter, daftar dokter ditampilkan.
          .map((dokter) => (
            <Grid key={dokter.doctor_id} item xs={12} md={6}>
              <Card>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <CardMedia
                      className='doctor-thumbnail'
                      component='img'
                      image={dokter.photo.formats.small}
                      alt={dokter.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <CardContent>
                      <Typography variant='h5' component='div'>
                        {dokter.name}
                      </Typography>

                      <Typography
                        variant='subtitle1'
                        color='text.secondary'
                        gutterBottom>
                        {dokter.hospital[0].name} - {dokter.specialization.name}
                      </Typography>

                      <Typography variant='caption' gutterBottom>
                        {parse(dokter.about_preview)}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing className='parentFlexRight'>
                      <Chip variant='outlined' label={dokter.price.formatted} />
                      <Button
                        style={{ marginLeft: "2%" }}
                        onClick={(e) => {
                          setDataDetailDokter(dokter);
                          setOpenDetailDokter(true);
                        }}
                        variant='contained'>
                        Detail
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
      </Grid>
      {/* Bagian daftar dokter selesai   */}
    </div>
  );
}
